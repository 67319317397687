import request from '@/utils/request'

export function getDepartments(){
    return request({
        url:'/department',
        method:"GET"
    })
}

export function getFacultyDepartments(){
    return request({
        url:'/department',
        params:{
            isFaculty:true
        },
        method:"GET"
    })
}

export function getDepJobTree(){
    return request({
        url:'/department',
        params:{
            isExpand:true
        },
        method:"GET"
    })
}
