import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_3 = {
  style: {
    "height": "calc(100vh - 60px)"
  }
};
import { Expand, Fold, Folder, Message } from "@element-plus/icons-vue";
import store from "@/vuex/store";
import { useRouter } from "vue-router";
import { computed, ref, reactive, toRaw } from "vue";
import { Icon } from '@iconify/vue';
export default {
  __name: 'SideBar',
  setup(__props) {
    const routes = computed(() => store.getters.allocationRoute);
    const router = useRouter();
    const isCollapse = ref(false);
    const clickToCollapse = () => {
      isCollapse.value = !isCollapse.value;
    };
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, {
        height: "calc(100vh - 60px)"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          collapse: isCollapse.value,
          class: "el-menu-vertical-demo",
          "default-active": _unref(router).currentRoute.value.path,
          "default-openeds": ['0']
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value[0].children, (route, index) => {
            return _openBlock(), _createBlock(_component_el_sub_menu, {
              key: route.id,
              index: index + ''
            }, {
              title: _withCtx(() => [_createVNode(_unref(Icon), {
                width: "22",
                color: "#2ecc71",
                icon: route.icon
              }, null, 8, ["icon"]), _createElementVNode("span", _hoisted_2, _toDisplayString(route.name), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.children, (child, child_index) => {
                return _openBlock(), _createBlock(_component_router_link, {
                  key: child_index,
                  style: {
                    "text-decoration": "none"
                  },
                  to: routes.value[0].path + '/' + child.path
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_menu_item, {
                    index: routes.value[0].path + '/' + child.path
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(child.meta.title), 1)]),
                    _: 2
                  }, 1032, ["index"])]),
                  _: 2
                }, 1032, ["to"]);
              }), 128))]),
              _: 2
            }, 1032, ["index"]);
          }), 128))]),
          _: 1
        }, 8, ["collapse", "default-active"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        onClick: clickToCollapse,
        plain: "",
        class: "foldButton"
      }, {
        icon: _withCtx(() => [!isCollapse.value ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_unref(Fold))]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1
        }, {
          default: _withCtx(() => [_createVNode(_unref(Expand))]),
          _: 1
        }))]),
        _: 1
      })])]);
    };
  }
};