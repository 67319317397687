import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import store from "@/vuex/store";
import Cookies from "js-cookie";

// const debounce = (fn, delay) => {
//   let timer
//   return (...args) => {
//     if (timer) {
//       clearTimeout(timer)
//     }
//     timer = setTimeout(() => {
//       fn(...args)
//     }, delay)
//   }
// }
//
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//   constructor(callback) {
//     callback = debounce(callback, 200);
//     super(callback);
//   }
// }

export default {
  __name: 'App',
  setup(__props) {
    // 解决表格变化引起的ResizeObserver loop completed with undelivered notifications.报错

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};