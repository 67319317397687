import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../assets/logo.png';
const _hoisted_1 = {
  class: "box"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", {
    class: "loader"
  }, null, -1), _createElementVNode("div", {
    class: "coin"
  }, [_createElementVNode("img", {
    class: "engraving",
    src: _imports_0
  })], -1)]));
}