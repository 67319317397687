import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/logo_ch.png';
const _hoisted_1 = {
  class: "toolbar"
};
const _hoisted_2 = {
  style: {
    "width": "100%",
    "padding": "8px",
    "display": "flex",
    "align-items": "center",
    "justify-content": "flex-start",
    "align-content": "center"
  }
};
const _hoisted_3 = {
  class: "menu-footer"
};
import { Setting } from "@element-plus/icons-vue";
import { logout } from "@/api/user";
import { HttpStatusCode } from "axios";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import store from "@/vuex/store";
import { computed, onMounted, ref } from "vue";
import { clearAuthKey } from "@/utils/auth";
export default {
  __name: 'NavBar',
  setup(__props) {
    const router = useRouter();
    const name = computed(() => {
      return store.getters.name;
    });
    const avatar = computed(() => {
      return store.getters.avatar;
    });
    const roles = computed(() => store.getters.roles);
    const clickLogout = () => {
      logout().then(res => {
        if (res.data.code > 200) {
          ElNotification({
            title: '错误',
            message: '登出失败，请稍后再试!',
            type: 'error'
          });
          return;
        }
        // 清除数据
        clearAuthKey(); // 清除storage
        store.dispatch('user/clearState');
        store.dispatch('permission/clearState');
        ElNotification({
          title: '信息',
          message: '你已经成功退出登录!',
          type: 'info'
        });
        router.push('login');
      }).catch(() => {
        ElNotification({
          title: '错误',
          message: '登出失败，请检查服务器配置!',
          type: 'error'
        });
      });
    };
    const clickToHome = () => {
      router.push('/admin');
    };
    return (_ctx, _cache) => {
      const _component_Bell = _resolveComponent("Bell");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_badge = _resolveComponent("el-badge");
      const _component_el_avatar = _resolveComponent("el-avatar");
      const _component_el_text = _resolveComponent("el-text");
      const _component_el_space = _resolveComponent("el-space");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_tag = _resolveComponent("el-tag");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "logo",
        onClick: clickToHome
      }, _cache[0] || (_cache[0] = [_createElementVNode("img", {
        alt: "logo of ChangHong",
        src: _imports_0
      }, null, -1)])), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_popover, {
        placement: "bottom",
        "popper-style": {
          padding: 0
        },
        width: 250,
        trigger: "click"
      }, {
        reference: _withCtx(() => [_createVNode(_component_el_badge, {
          "badge-style": {
            width: '5px',
            height: '15px',
            fontSize: '12px'
          },
          "show-zero": false,
          value: 0
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, {
            size: 16,
            style: {
              "cursor": "pointer",
              "margin-top": "1px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_Bell)]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "menu-header"
        }, "提醒", -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_avatar, {
          size: 26,
          style: {
            "margin-right": "10px"
          },
          src: avatar.value
        }, null, 8, ["src"]), _createVNode(_component_el_space, {
          alignment: "normal",
          size: 2,
          direction: "vertical",
          style: {
            "margin-right": "5px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_text, {
            size: "small"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("您暂时没有提醒.")])),
            _: 1
          }), _createVNode(_component_el_text, {
            size: "small",
            type: "info"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("今天 10:11")])),
            _: 1
          })]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
          size: "small",
          style: {
            "font-size": "12px"
          },
          link: "",
          type: "primary"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("显示全部")])),
          _: 1
        }), _createVNode(_component_el_button, {
          size: "small",
          style: {
            "font-size": "12px"
          },
          link: "",
          type: "info"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("全部已读")])),
          _: 1
        })])]),
        _: 1
      }), _createVNode(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("个人中心")])),
            _: 1
          }), _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("修改密码")])),
            _: 1
          }), _createVNode(_component_el_dropdown_item, {
            onClick: clickLogout
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("退出登录")])),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 16,
          style: {
            "cursor": "pointer",
            "margin-left": "16px",
            "margin-right": "16px",
            "margin-top": "1px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_unref(Setting))]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_avatar, {
        size: 32,
        style: {
          "margin-right": "10px"
        },
        src: avatar.value
      }, null, 8, ["src"]), _createVNode(_component_el_space, {
        alignment: "center",
        size: 2,
        direction: "vertical",
        style: {
          "margin-right": "5px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(name.value) + " ", 1), roles.value.length ? (_openBlock(), _createBlock(_component_el_tag, {
          key: 0,
          style: _normalizeStyle({
            borderColor: roles.value[0].color,
            color: roles.value[0].color
          }),
          effect: "plain",
          round: "",
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(roles.value[0].alias), 1)]),
          _: 1
        }, 8, ["style"])) : _createCommentVNode("", true)]),
        _: 1
      })])], 64);
    };
  }
};