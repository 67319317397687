import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "frame"
};
import { AppMain, Navbar, Sidebar } from './components';
import app from "@/main";
import NavBar from "@/layout/components/NavBar.vue";
import SideBar from "@/layout/components/SideBar.vue";
export default {
  __name: 'LayoutIndex',
  setup(__props) {
    app.component({
      AppMain,
      Navbar,
      Sidebar
    });
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_el_main = _resolveComponent("el-main");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "layout-container-demo"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          style: {
            "background-color": "white",
            "text-align": "right",
            "font-size": "12px",
            "box-shadow": "0 2px 4px #00000014",
            "z-index": "5"
          }
        }, {
          default: _withCtx(() => [_createVNode(NavBar)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_container, {
        class: "layout-container-demo",
        style: {
          "height": "100vh"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_aside, {
          width: "auto"
        }, {
          default: _withCtx(() => [_createVNode(SideBar)]),
          _: 1
        }), _createVNode(_component_el_container, null, {
          default: _withCtx(() => [_createVNode(_component_el_main, {
            class: "main"
          }, {
            default: _withCtx(() => [_createVNode(_unref(AppMain))]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};