import {getInfo, login, setUserAvatar} from "@/api/user";
import {Promise} from "es6-promise";
import {reactive} from "vue";
import {getUserId} from "@/utils/auth";
import {getClasses} from "@/api/classes";
import store from "@/vuex/store";
import {getDepJobTree} from "@/api/department";



/**
 * 默认state，方便用于初始化。
 */
const getDefaultState = ()=>{
    return reactive({
        classes: '',
        faculty_options: '',
    })
}

const state = getDefaultState()

const mutations = {
    SET_CLASSES: (state, classes) => {
        state.classes = classes
    },
    SET_FACULTY_OPTIONS: (state, faculty_options) => {
        state.faculty_options = faculty_options
    },
    CLEAR_STATE: ()=>{
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    // user login
    getOptions({commit}) {
        getClasses().then(res=>{
            console.log('SET_CLASSES',res.data.data)
            commit('SET_CLASSES',res.data.data)

            // 拉取数据并格式化树的内容
            getDepJobTree().then(depRes => {
                // 格式化部门
                const departments = []
                depRes.data.data.forEach(dep => {
                    departments.push({
                        value: dep.id,
                        label: dep.name,
                        isFaculty: dep.isFaculty,
                    },)
                })

                const faculties =departments.filter(dep=>dep.isFaculty === 1).map(faculty=>{
                    return {
                        ...faculty,
                        children:[]
                    }
                })


                res.data.data.filter(clazz=>{
                    faculties.forEach(faculty=>{
                        if (clazz.depId === faculty.value){
                            faculty.children.push({value:clazz.id,label:clazz.className})
                        }
                    })
                })

                console.log("faculties",faculties)
                commit('SET_FACULTY_OPTIONS',faculties)


            }).catch(err => {
                console.log(err)
            })


        }).catch(error=>{
            console.log("获取全校班级信息",error)
        })
    },
    clearState({commit}){
        commit('CLEAR_STATE')
    },

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}