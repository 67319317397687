import {reactive} from "vue";
import {getDepJobTree} from "@/api/department";

const getDefaultState = ()=>{
    return reactive({
        departments:[],
        jobs:[],
        tree:[]
    })
}

// 以下为store的对象
const state = getDefaultState()

const mutations = {
    SET_DEPARTMENTS: (state, departments) => {
        state.departments = departments
    },
    SET_JOBS: (state, jobs) => {
        state.jobs = jobs
    },
    SET_TREE: (state, tree) => {
        state.tree = tree
    },
    CLEAR_STATE: ()=>{
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    formatDepJobs({commit}){
        // 拉取数据并格式化树的内容
        getDepJobTree().then(res => {
                //保存树形数据
                commit('SET_TREE',res.data.data)
                // 格式化部门
                const arr = []
                const arr_jobs = []
                res.data.data.forEach(dep => {
                    arr.push({
                        value: dep.id,
                        label: dep.name,
                        isFaculty: dep.isFaculty,
                    },)
                })
                commit('SET_DEPARTMENTS',arr)
                // 格式化工作
                res.data.data.forEach(dep =>{
                    if (dep.children){
                        arr_jobs.push(...dep.children)
                    }
                })
                commit('SET_JOBS',arr_jobs)
        }).catch(err => {
            console.log(err)
        })
    },

    clearState({commit}){
        commit('CLEAR_STATE')
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
