import request from '@/utils/request'



export function getRoutesById(userId) {
    return request({
        url: `/route/getRoute`,
        params:{userId},
        method: 'GET'
    })

}