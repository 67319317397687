const TokenKey = 'token'
const UserIdKey = 'user-id'

export function getToken() {
    return window.localStorage.getItem(TokenKey)
}

export function getUserId() {
    return window.localStorage.getItem(UserIdKey)
}


export function setToken(token) {
    localStorage.setItem(TokenKey, token)
}
export function setUserId(id) {
    localStorage.setItem(UserIdKey, id);
}

export function removeTokenKey() {
    localStorage.removeItem(TokenKey)
}
export function removeUserIdKey() {
    localStorage.removeItem(UserIdKey)
}
export function clearAuthKey(){
    removeUserIdKey()
    removeTokenKey()
}