
// import { defineAsyncComponent } from 'vue'
// 在同包目录下加载route组件
export default (view) =>{

    if (view.includes('layout')){
        return ()=>import(`@/${view}.vue`)
    }

    return  ()=>import(`@/views/${view}.vue`)

}