import request from '@/utils/request'


export function getEmpInfo(params){
    return request({
        url:'/employee',
        params,
        method:"GET"
    })
}

export function getEmpInfoByID(id){
    return request({
        url:'/employee',
        params:{ id },
        method:"GET"
    })
}

/**
 * 批量删除员工
 * @param ids
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function batchDelEmpByIds(ids){
    return request({
        url:'/employee/batch',
        data:ids,
        method:"DELETE"
    })
}

export function getEmpLearnExpByID(id){
    return request({
        url:'/employee/learn_exp',
        params:{ id },
        method:"GET"
    })
}

export function getEmpWorkExpByID(id){
    return request({
        url:'/employee/work_exp',
        params:{ id },
        method:"GET"
    })
}

export function deleteEmpLearnExpByIds(ids){
    return request({
        url:'/employee/learn_exp',
        data:ids,
        method:"DELETE"
    })
}

export function deleteEmpWorkExpByIds(ids){
    return request({
        url:'/employee/work_exp',
        data:ids,
        method:"DELETE"
    })
}

export function changeStatus(id,status){
    return request({
        url:'/employee/change-status',
        params:{ id,status },
        method:"POST"
    })
}

export function saveEmp(employee){
    return request({
        url:'/employee',
        data:employee,
        method:"POST"
    })
}
export function saveEmpBatch(employeeList){
    return request({
        timeout:50000,
        url:'/employee/batch',
        data:employeeList,
        method:"POST",
    })
}

export function saveEmpLearnExp(exps){
    return request({
        url:'/employee/learn_exp',
        data:exps,
        method:"POST"
    })
}

export function saveEmpWorkExp(exps){
    return request({
        url:'/employee/work_exp',
        data:exps,
        method:"POST"
    })
}

export function hasPhoneNumber(phoneNumber){
    return request({
        url:'/employee/check-phone',
        params:{phoneNumber},
        method:"GET"
    })
}


export function saveEmpAvatar(params){
    return request({
        url:'/employee/avatar',
        params,
        method:"POST"
    })
}